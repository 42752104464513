<template>
  <div class="w-100">
    <b-card class="mt-3">
      <div class="w-100">
        <vue-apex-charts class="w-75" type="bar" :options="options" :series="series" />
      </div>
    </b-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'TourBar',
  components: {
    VueApexCharts,
  },
  props: {
    contractLabel: {
      required: true,
    },
    contractPaid: {
      required: true,
    },
    contractNotPaid: {
      required: true,
    },
  },
  data() {
    return {
      options: {
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          categories: [2018, 2019, 2020, 2021, 2022]
        }
      },
      series: [{
        name: 'series-1',
        data: [50, 40, 55, 60, 29,],
      },
        {
          name: 'series-1',
          data: [40, 30, 45, 50, 19,]
        }]
    }
  },
  watch: {
    contractLabel: {
      immediate: true,
      handler(val) {
        this.options = {...this.options,...{xaxis:{categories: val}}}
        this.series = [
            {name: 'Paid',data: this.contractPaid},
            {name: 'Not Paid',data: this.contractNotPaid},
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>
